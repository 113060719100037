<template>
  <v-container>
    <PriceTable />
  </v-container>
</template>

<script>
import PriceTable from "@/components/PriceTable.vue";

export default {
  components: {
    PriceTable,
  },
};
</script>